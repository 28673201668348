<template>
  <v-dialog v-model="dialog.status" @click:outside="closeDialog" @keydown.esc="closeDialog" max-width="800px">
    <v-form ref="form" @submit.prevent="submitForm" :disabled="disabled">
      <v-card :loading="loading">
        <v-card-title>
          <span class="headline" v-if="dialog.item">Редактировать</span>
          <span class="headline" v-else>Добавить</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md=""
              >
                <v-autocomplete
                  v-model="item.site_id"
                  auto-select-first
                  item-text="url"
                  item-value="id"
                  :items="sites.data"
                  label="Сайт"
                  hint="Например: multinet.su"
                  :error-messages="errors.site_id || errors.unique"
                  clearable
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                    v-model="item.provider_id"
                    item-text="name"
                    item-value="id"
                    :items="providers.data"
                    label="Провайдер"
                    hint="Например: Ростелеком"
                    :error-messages="errors.provider_id || errors.unique"
                    clearable
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img contain :src="img(JSON.parse(data.item.logo))"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
                <v-autocomplete
                  v-model="item.category_id"
                  item-text="name"
                  item-value="id"
                  :items="categories.data"
                  label="Категория"
                  :error-messages="errors.category_id || errors.unique"
                  clearable
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        Ничего не найдено
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <v-textarea
                  label="Заголовок"
                  type="text"
                  v-model="item.title"
                  hint="Можно использовать переменные %category_name, %city_name, %city_with_type, %region_name, %region_second_name"
                  :error-messages="errors.title"
                ></v-textarea>
                <v-textarea
                  label="Описание"
                  type="text"
                  v-model="item.description"
                  hint="Можно использовать переменные %category_name, %city_name, %city_with_type, %region_name, %region_second_name"
                  :error-messages="errors.description"
                ></v-textarea>
                <v-textarea
                  label="H1"
                  type="text"
                  v-model="item.h1"
                  hint="Можно использовать переменные %category_name, %city_name, %city_with_type, %region_name, %region_second_name"
                  :error-messages="errors.h1"
                ></v-textarea>
                <v-textarea
                  label="Текст под H1 (sub_h1)"
                  type="text"
                  v-model="item.sub_h1"
                  hint="Можно использовать переменные %category_name, %city_name, %city_with_type, %region_name, %region_second_name"
                  :error-messages="errors.sub_h1"
                ></v-textarea>
                <v-textarea
                  label="Текст над тарифами (H2)"
                  type="text"
                  v-model="item.h2"
                  hint="Можно использовать переменные %category_name, %city_name, %city_with_type, %region_name, %region_second_name"
                  :error-messages="errors.h2"
                ></v-textarea>

                <v-textarea
                  label="Текст под категориями (sub_h2)"
                  type="text"
                  v-model="item.sub_h2"
                  hint="Можно использовать переменные %category_name, %city_name, %city_with_type, %region_name, %region_second_name"
                  :error-messages="errors.sub_h2"
                />

                <v-textarea
                  label="Текст под категориями на странице города (sub_h2_dynamic)"
                  type="text"
                  v-model="item.sub_h2_dynamic"
                  hint="Можно использовать переменные %category_name, %city_name, %city_with_type, %region_name, %region_second_name"
                  :error-messages="errors.sub_h2_dynamic"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog"
            :disabled="disabled"
          >
            Отмена
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="disabled"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
  export default {
    data () {
      return {
        disabled: false,
        loading: false,
        errors: false,
        search: null,
      }
    },
    created() {
      this.clearSites();
      this.clearProviders();
      this.clearCategories();
    },
    async mounted () {
      this.disabledAndLoading();
      await this.getSites();
      await this.getProviders();
      await this.getCategories();
      this.nonDisabledAndLoading();
    },
    destroyed () {
      this.clearSites();
      this.clearProviders();
      this.clearCategories();
    },
    computed: {
      ...mapGetters({
        filter_dialog: 'filter_dialog/FILTER_DIALOG',
        dialog: 'dialog/DIALOG',
        seo: 'seo/SEO',
        sites: 'sites/SITES',
        providers: 'providers/PROVIDERS',
        categories: 'categories/CATEGORIES',
      }),
      sites_api: function () {
        return process.env.VUE_APP_SITES_API_URL;
      },
      item: function () {
        var item = this.dialog.item;
        if (item) {
          return {
            id: item.id,
            site_id: item.site?.id,
            provider_id: item.provider?.id,
            category_id: item.category?.id,
            title: item.title,
            description: item.description,
            h1: item.h1,
            sub_h1: item.sub_h1,
            h2: item.h2,
            sub_h2: item.sub_h2,
            sub_h2_dynamic: item.sub_h2_dynamic,
          }
        }
        else {
          return {
            id: '',
            site_id: '',
            provider_id: '',
            category_id: '',
            title: '',
            description: '',
            h1: '',
            sub_h1: '',
            h2: '',
            sub_h2: '',
            sub_h2_dynamic: '',
          }
        }
      },
      params: function () {
        let params = this.filter_dialog.item ? Object.assign({}, this.filter_dialog.item) : {};
        params.page = this.seo.meta.current_page;
        params.per_page = this.seo.meta.per_page;
        return params;
      }
    },
    methods: {
      ...mapActions({
        unsetDialog: 'dialog/SET_DIALOG_FALSE',
        disabledAndLoading: 'data_table/SET_TABLE_DISABLE_AND_LOADING',
        nonDisabledAndLoading: 'data_table/RESET_TABLE_DISABLE_AND_LOADING',
        getSeo: 'seo/GET_SEO',
        storeSeo: 'seo/STORE_SEO',
        updateSeo: 'seo/UPDATE_SEO',
        setSnackbar: 'snackbar/SET_SNACKBAR_TRUE',
        getSites: 'sites/GET_ALL_SITES',
        getProviders: 'providers/GET_ALL_PROVIDERS',
        getCategories: 'categories/GET_ALL_CATEGORIES',
        clearSites: 'sites/CLEAR_SITES',
        clearProviders: 'providers/CLEAR_PROVIDERS',
        clearCategories: 'categories/CLEAR_CATEGORIES',
      }),
      img (logo) {
        let url = require(`@/assets/img/no-img.png`)
        if (logo?.svg) {
          url = this.sites_api+logo.svg
        } else if(logo?.regular?.lg) {
          url = this.sites_api+logo.regular.lg
        }

        return url
      },

      closeDialog () {
        this.unsetDialog();
        this.errors = false;
      },

      clearItem () {
        this.item.site_id = null;
        this.item.provider_id = null;
        this.item.category_id = null;
        this.item.text = null;
        this.item.description = null;
        this.item.h1 = null;
        this.item.sub_h1 = null;
        this.item.h2 = null;
        this.item.sub_h2 = null;
        this.item.sub_h2_dynamic = null;
      },

      successActions (snackbar) {
        this.setSnackbar(snackbar);
        this.closeDialog();
        this.errors = false;
        this.disabledAndLoading();
        this.getSeo(this.params)
          .then(() => {
            this.nonDisabledAndLoading();
          });
      },

      submitForm () {
        this.disabled = this.loading = true;
        if (this.dialog.item) {
          this.updateSeo(this.item)
            .then(() => {
                this.successActions({message: "Сео данные изменены"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
        else {
          this.storeSeo(this.item)
            .then(() => {
              this.clearItem();
              this.successActions({message: "Сео данные добавлены"});
            })
            .catch(e => {
              if (e.response) {
                this.errors = e.response.data.errors;
              } else {
                this.setSnackbar({message: "Ошибка сервера", color: "red"});
              }
            })
            .finally(() => {
              this.disabled = this.loading = false;
            });
        }
      }
    }
}
</script>
